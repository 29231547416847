export const Items = [
  {
    name: 'Breakfast',
    // description: 'YOUR FAVOURITE MEAL SERVED ALL DAY LONG.',
    image: 'images/menu/breakfast.png',
    menuItem: [
      {
        menuImg: 'images/menu/menuItems/breakfast.jpg',
      },
      {
        menuImg: 'images/menu/menuItems/breakfast2.jpg',
      },
    ],
  },
  {
    name: 'Bruschetta',
    //  description: "THERE'S ALWAYS A NEW FLAVOUR TO EXPLORE.",
    image: 'images/menu/toast.png',
    menuItem: [
      {
        menuImg: 'images/menu/menuItems/bruschetta.jpg',
      },
    ],
  },
  {
    name: 'Pizza',
    //  description: 'BITE SIZE PIZZA? COMING RIGHT UP.',
    image: 'images/menu/pizza.png',
    menuItem: [
      { menuImg: 'images/menu/menuItems/pizza.jpg' },
      {
        menuImg: 'images/menu/menuItems/pizza2.jpg',
      },
    ],
  },
  {
    name: 'Sandwiches & Burgers',
    //  description: 'HUMBLE YUMMY FLAVOURS.',
    image: 'images/menu/sandwich.png',
    menuItem: [
      {
        menuImg: 'images/menu/menuItems/sandwiches-burgers.jpg',
      },
    ],
  },
  // {
  //   name: 'Burger',
  //   //  description: 'EVERY MEAT LOVERS FAVOURITE.',
  //   image: 'images/menu/burger.png',
  //   menuItem: [
  //     {
  //       menuImg: 'images/menu/menuItems/burger.png',
  //     },
  //   ],
  // },
  {
    name: 'Salad',
    //  description: 'FRESHNESS WITH FLAVOUR GUARANTEED.',
    image: 'images/menu/salad.png',
    menuItem: [
      {
        menuImg: 'images/menu/menuItems/salad.jpg',
      },
      {
        menuImg: 'images/menu/menuItems/salad2.jpg',
      },
    ],
  },
  {
    name: 'Pasta',
    //  description: 'LOOKS AS GOOD AS IT TASTES!',
    image: 'images/menu/pasta.png',
    menuItem: [
      {
        menuImg: 'images/menu/menuItems/pasta.jpg',
      },
      {
        menuImg: 'images/menu/menuItems/pasta2.jpg',
      },
    ],
  },
  {
    name: 'Main Course',
    //  description: '',
    image: 'images/menu/main-course.png',
    menuItem: [
      {
        menuImg: 'images/menu/menuItems/main-course.jpg',
      },
    ],
  },
  {
    name: 'International street Food',
    //  description: '',
    image: 'images/menu/intl-street-food-main.png',
    menuItem: [
      {
        menuImg: 'images/menu/menuItems/intl-street-food.jpg',
      },
      {
        menuImg: 'images/menu/menuItems/intl-street-food2.jpg',
      },
    ],
  },
  {
    name: 'Coffee & Tea',
    //  description: '',
    image: 'images/menu/hot-beverage.png',
    menuItem: [
      {
        menuImg: 'images/menu/menuItems/hot-beverage.jpg',
      },
    ],
  },
  {
    name: 'Cold Beverage',
    //  description: 'SLUSHY OR COLD COFFEE?',
    image: 'images/menu/cold-drink.png',
    menuItem: [
      {
        menuImg: 'images/menu/menuItems/cold-drink.jpg',
      },
    ],
  },
  /* {
    name: 'Beverages',
    description: 'SLUSHY OR COLD COFFEE?',
    image: 'images/menu/beverages.jpg',
    menuItem: [
      {
        menuImg: 'images/menu/menuItems/beverages.jpg',
      },
       {
        menuImg: 'images/menu/menuItems/beverage2.jpg',
      },
    ],
  },
   {
     name: 'Desserts',
     description: 'SWEET TOOTH CRAVINGS SATISFIED.',
     image: 'images/menu/dessert.jpg',
     menuItem: [
       {
         menuImg: 'images/menu/menuItems/desserts.jpg',
       },
     ],
   },*/
];
